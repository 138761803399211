import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingScreen = ({ isVisible }) => {
  return (
    <div className={`fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center
     bg-red-900 z-50 transition-transform duration-800 transform ${isVisible ? '' : '-translate-y-full'}`}>
      <div className="text-white mb-4">
        <FontAwesomeIcon icon={faSpinner} spin className="text-4xl mr-2" />
        Loading...
      </div>
      <img src="../img/logo-gerussi.png" alt="Company Logo" className="h-20" />
    </div>
  );
};

export default LoadingScreen;
