import React from 'react';
import { useTranslation } from 'react-i18next';
import LavoraConNoiForm from '../components/LavoraConNoiForm';

function Contacts() {
    const { t } = useTranslation("global");



    return  <div className="main-content transition-opacity duration-500 w-full pt-0">
          {/* Hero Section */}
          <section className="h-[50vh] relative bg-[url('/img/workwithus.webp')] bg-center bg-cover ">
        < div className="absolute inset-0 bg-black opacity-50" ></div>
        <div className="container mx-auto px-4 text-center h-full flex">
          <h1 className="text-4xl md:text-5xl font-bold text-white z-30 uppercase self-center">
            {t("form.h1")}
          </h1>
        </div>
      </section>

    <LavoraConNoiForm />
      </div>
}

export default Contacts;