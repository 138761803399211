import React, { useState,  useEffect, } from 'react';
import { useTranslation } from 'react-i18next';
import ParallaxSection from '../components/ParallaxSection';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';
import VideoSection from '../components/VideoSection';

function Lavorazioni() {
    const { t } = useTranslation("global");
    const [videoReady, setVideoReady] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
  
    useEffect(() => {
      const videoElement = document.getElementById("background-video");
      if (videoElement) {
        console.log("Video element found!")
  
        const handleCanPlayThrough = () => {
          console.log("Video is ready to play through!")
          setVideoReady(true);
          setIsLoading(false);
        };
        videoElement.addEventListener("canplaythrough", handleCanPlayThrough);
  
        return () => {
          console.log("Cleanup: Removing event listener")
          videoElement.removeEventListener("canplaythrough", handleCanPlayThrough);
        };
      } else {
        console.log("Video element not found!")
      }
    }, []);

    return   <>
       <div className="h-[95vh] lg:h-screen flex flex-col">
    <section className="flex-1 relative">
      {isLoading && (
        <img
          src="../img/videofallback.webp"
          alt="Fallback Image"
          className="absolute inset-0 w-full h-full object-cover"
        />
      )}

      <video
        id="background-video"
        autoPlay
        muted
        loop
        key={'material_video'} // Add this key prop
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${videoReady ? 'opacity-100' : 'opacity-0'}`}
      >
        <source src="../vid/manufacturing_video_desktop.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h1 className="text-4xl lg:text-6xl uppercase w-2/3 absolute left-1/2 -translate-x-1/2 bottom-10 text-white text-center font-bold">
      {t('lav.h1')}
      </h1>
    </section>
  </div>
       <div className="main-content transition-opacity duration-500 w-full overflow-x-hidden">


   

          {/* <VideoSection src="../img/PreviewTetto.mp4"></VideoSection> */}


    <div className="pt-10 md:px-10 lg:px-20 2xl:px-40 pb-10">
 
    <div className='bg-white p-10'>


      <div className="mb-40 mt-10 flex relative flex-col lg:flex-row lg:h-[80vh]">
        <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute left-0 lg:-translate-x-1/2 w-full lg:w-3/5' src="/img/Aprifibra-per-imbottitura-CD4001-Gerussi.webp"/>
        </div>
          <div className='w-full lg:w-3/5 mt-4 self-center'>
          <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat1h')}</h3>
          <p className="text-base">{t('lav.cat1p')}</p>
          <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row-reverse lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute ' src="/img/Sfilacciatrice-di-scarti-tessili-SF2CA-Gerussi.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
            <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat2h')}</h3>
            <p className="text-base">{t('lav.cat2p')}</p>
            <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute left-0 lg:-translate-x-1/2 w-full lg:w-3/5' src="/img/Riempitrice-R-3PV2-Gerussi.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
          <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat3h')}</h3>
          <p className="text-base">{t('lav.cat3p')}</p>
          <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row-reverse lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute lg:-translate-x-1/4 w-[80%]' src="/img/Rimepitrice-con-pesatura-automatica-per-imbottire-SR5HMI.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
            <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat4h')}</h3>
            <p className="text-base">{t('lav.cat4p')}</p>
            <a href="/contacts" className="text-gerussiRed underline">{t('lav.cat1h')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute left-0 lg:-translate-x-1/3 w-full lg:w-2/5' src="/img/Mescolatore-per-materiali-da-imbottitura-M1P-Gerussi.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
          <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat5h')}</h3>
          <p className="text-base">{t('lav.cat5p')}</p>
          <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row-reverse lg:h-[60vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute lg:translate-y-20 lg:translate-x-20' src="/img/Console-for-filling-machines-Gerussi-1.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
            <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat6h')}</h3>
            <p className="text-base">{t('lav.cat6p')}</p>
            <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

    </div>
    </div>
    <ContactForm />
  </div>
  </>
}

export default Lavorazioni;