// i18n.js
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

// Import your translation files
import global_en from './translations/en.json'
import global_it from './translations/it.json'
import global_fr from './translations/fr.json'
import global_es from './translations/es.json'

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: false, // Disable fallback to see missing translations
    debug: false,
    interpolation: { escapeValue: false }, // Avoids XSS in translations
    lng: 'en',
    resources: {
      en: { global: global_en },
      it: { global: global_it },
      fr: { global: global_fr },
      es: { global: global_es },
    },
  });
 export default i18next; 
 