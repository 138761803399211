import { useTranslation } from "react-i18next";
import FlipImage from "../../components/FlipImage";
import ContactForm from "../../components/Contact";

export default function MatPillow() {

    const { t } = useTranslation("global");
    
    return <>
        
        <div className={`main-content transition-opacity duration-500 overflow-x-hidden`}>
        
        <div className="flex flex-col md:flex-col items-center pt-20 mb-10 px-2">
            <img src="/img/Materiale-Piuma-per-imbottitura-Gerussi.webp" className="w-full lg:w-1/3 rounded-lg" />
            <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('matPillow.h1')}</h1>
            <p className="text-center">{t('matPillow.p')}</p>

        </div>

        <ContactForm />
        </div>
    </>
}