// src/utils/LocalizedNavLink.js

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routesConfig } from '../routesConfig';

const LocalizedNavLink = ({ to, ...props }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  // Find the current language group based on the current path
  const currentLangGroup = routesConfig.find(group =>
    group.basePath && location.pathname.startsWith(group.basePath)
  ) || routesConfig.find(group => group.basePath === '');

  const basePath = currentLangGroup.basePath;

  // Construct the new 'to' prop by combining the base path and the provided 'to' prop
  const localizedTo = `${basePath}${to}`.replace('//', '/');

  return <NavLink to={localizedTo} {...props} />;
};

export default LocalizedNavLink;
