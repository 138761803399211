import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const SF3CAL = () => {
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('sf3ca.img1Src'),
    img1Alt: t('sf3ca.img1Alt'),
    title: t('sf3ca.title'),
    subtitle: t('sf3ca.subtitle'),
    description: t('sf3ca.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('sf3ca.features', { returnObjects: true }))
      ? t('sf3ca.features', { returnObjects: true })
      : [],

    tecnicals: t('sf3ca.tecnicals', { returnObjects: true }),
    directives: t('sf3ca.directives'),
    img2Src: t('sf3ca.img2Src'),
    img2Alt: t('sf3ca.img2Alt'),
    options: t('sf3ca.options', { returnObjects: true }),
    warranty: t('sf3ca.warranty'),
    specs: {
      headers: t('sf3ca.specs.headers', { returnObjects: true }),
      values: t('sf3ca.specs.values', { returnObjects: true })
    },
    relatedProducts: t('sf3ca.relatedProducts', { returnObjects: true })
  };

  return <ProductTemplate {...productData} />;
};

export default SF3CAL;
