import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const MSF200 = () => {
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('msf200.img1Src'),
    img1Alt: t('msf200.img1Alt'),
    title: t('msf200.title'),
    subtitle: t('msf200.subtitle'),
    description: t('msf200.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('msf200.features', { returnObjects: true }))
      ? t('msf200.features', { returnObjects: true })
      : [],

    tecnicals: t('msf200.tecnicals', { returnObjects: true }),
    img2Src: t('msf200.img2Src'),
    img2Alt: t('msf200.img2Alt'),
    options: t('msf200.options', { returnObjects: true }),
    warranty: t('msf200.warranty'),
    specs: {
      headers: t('msf200.specs.headers', { returnObjects: true }),
      values: t('msf200.specs.values', { returnObjects: true })
    },
    relatedProducts: t('msf200.relatedProducts', { returnObjects: true })
  };

  return <ProductTemplate {...productData} />;
};

export default MSF200;
