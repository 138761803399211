import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const IC1 = () => {
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('ic1.img1Src'),
    img1Alt: t('ic1.img1Alt'),
    title: t('ic1.title'),
    subtitle: t('ic1.subtitle'),
    description: t('ic1.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('ic1.features', { returnObjects: true }))
      ? t('ic1.features', { returnObjects: true })
      : [],

    tecnicals: t('ic1.tecnicals', { returnObjects: true }),
    img2Src: t('ic1.img2Src'),
    img2Alt: t('ic1.img2Alt'),
    options: t('ic1.options', { returnObjects: true }),
    warranty: t('ic1.warranty'),
    specs: {
      headers: t('ic1.specs.headers', { returnObjects: true }),
      values: t('ic1.specs.values', { returnObjects: true })
    },
    relatedProducts: t('ic1.relatedProducts', { returnObjects: true })
  };
  return <ProductTemplate {...productData} />;
};

export default IC1;
