import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const M1P = () => {
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('m1p.img1Src'),
    img1Alt: t('m1p.img1Alt'),
    title: t('m1p.title'),
    subtitle: t('m1p.subtitle'),
    description: t('m1p.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('m1p.features', { returnObjects: true }))
      ? t('m1p.features', { returnObjects: true })
      : [],

    tecnicals: t('m1p.tecnicals', { returnObjects: true }),
    img2Src: t('m1p.img2Src'),
    img2Alt: t('m1p.img2Alt'),
    options: t('m1p.options', { returnObjects: true }),
    warranty: t('m1p.warranty'),
    specs: {
      headers: t('m1p.specs.headers', { returnObjects: true }),
      values: t('m1p.specs.values', { returnObjects: true })
    },
    relatedProducts: t('m1p.relatedProducts', { returnObjects: true })
  };
  

  return <ProductTemplate {...productData} />;
};

export default M1P;
