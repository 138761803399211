import React, { useEffect, useRef, useState } from 'react';
import throttle from "https://cdn.skypack.dev/lodash@4.17.21/throttle";


const ParallaxSection = ({ image, children, heights = ['screen', 'screen'] }) => {
  const sectionRef = useRef(null);
  const [debug, setDebug] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [initialBeta, setInitialBeta] = useState(null);
  const [initialGamma, setInitialGamma] = useState(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const handleOrientation = throttle((event) => {
        const section = sectionRef.current;
        if (!section) return;

        const beta = event.beta;   // X-axis rotation (-180 to 180)
        const gamma = event.gamma;  // Y-axis rotation (-90 to 90)

        // Set initial values if not already set
        if (initialBeta === null || initialGamma === null) {
          setInitialBeta(beta);
          setInitialGamma(gamma);
        }

        // Calculate offsets based on initial values
        const offsetX = (gamma - initialGamma) * 1.2; // Adjust multiplier for sensitivity
        const offsetY = (-beta - initialBeta) * 1.2;   // Adjust multiplier for sensitivity

        section.style.backgroundPosition = `calc(50% + ${offsetX}px) calc(50% + ${offsetY}px)`;

        setDebug(`Beta: ${beta.toFixed(2)}, Gamma: ${gamma.toFixed(2)}`);
      }, 10); // Adjusted throttle timing for smoother updates

      window.addEventListener('deviceorientation', handleOrientation);
      return () => window.removeEventListener('deviceorientation', handleOrientation);
    } else {
      const handleMouseMove = (e) => {
        const section = sectionRef.current;
        if (!section) return;

        const { clientX, clientY } = e;
        const rect = section.getBoundingClientRect();
        const offsetX = ((clientX - rect.left - rect.width / 2) / rect.width) * -50;
        const offsetY = ((clientY - rect.top - rect.height / 2) / rect.height) * -50;

        section.style.backgroundPosition = `calc(50% + ${offsetX}px) calc(50% + ${offsetY}px)`;
      };

      const section = sectionRef.current;
      if (section) {
        section.addEventListener('mousemove', handleMouseMove);
      }

      return () => {
        if (section) {
          section.removeEventListener('mousemove', handleMouseMove);
        }
      };
    }
  }, [isMobile, initialBeta, initialGamma]);

  return (
    <section
      ref={sectionRef}
      className={`relative z-0 flex flex-col items-center scale-105 bg-cover bg-center bg-fixed justify-center overflow-hidden ${heights
        .map((height, index) =>
          height === 'screen' ? 'min-h-screen' : `${index === 0 ? 'min-h' : 'max-h'}-${height}`
        )
        .join(' ')}`}
      style={{
        backgroundImage: `url('${image}')`,
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="flex-grow flex flex-col justify-end items-center">
        {children}
       
      </div>
    </section>
  );
};

export default ParallaxSection;