import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ParallaxSection from '../components/ParallaxSection';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';
import VideoSection from '../components/VideoSection';

function Servizi() {
    const { t } = useTranslation("global");

    const [currentImage, setCurrentImage] = useState(0);
    
    const images = [
      '/img/Consulenza-e-progettazione-macchinari-e-soluzioni-imbottitura.webp',
      '/img/Macchina-per-imbottire.webp',
      '/img/Particolari-di-LEGAMATIC-Details-of-LEGAMATIC.webp',
      '/img/lavorazioni1.png',
      '/img/lavorazioni.png',
      // add more image URLs as needed
    ];

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      }, 5000); // Change image every 3 seconds
      return () => clearInterval(interval);
    }, []);
  

    return <div className="main-content transition-opacity duration-500 w-full">


    <div className=" bg-[url('/img/Consulenza-macchine-per-imbottire.png')] bg-contain bg-no-repeat bg-top relative h-[45vh] lg:h-screen ">
      <div className="absolute bottom-4 z-20 h-auto md:h-[30vh] px-4 md:px-40 py-10 w-full text-center">
              
        
            <h1 className='text-4xl md:text-5xl font-bold text-gerussiRed mb-3'>{t('serv.h1')}</h1>
            <p className="text-sm md:text-base">{t('serv.p')}.</p>
        </div>
      </div>


      {/*  slider  */}
<div className="relative z-0 w-full  h-[60vh] md:h-[80vh] overflow-hidden">
      <div className="absolute w-full h-full  overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute w-full bg-gray-800 bg-opacity-40 bg-blend-overlay h-full transition-translate duration-[1200ms] ease-[cubic-bezier(0.34,1.56,0.64,1)] transform ${
              index === currentImage ? 'translate-y-0 opacity-100 ' : 'translate-y-full  opacity-0'
            }`}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
        ))}
      </div>

      <div className="absolute z-10 bottom-5 lg:bottom-40 left-1/2 -translate-x-1/2 w-11/12  md:w-[560px] flex items-center justify-center">
        <div className="bg-white flex flex-col items-center p-6 rounded-lg text-center">
            
        <img src="../img/Raggruppa-487-q6i3d86dk9dt7e0bffumo8eljuwrcwy55rw4arlcv0.webp" alt="Image" 
        className="w-32 -mt-20" />
          <h3 className="text-2xl font-bold">{t('serv.industria4')}</h3>
          <p className="mt-2">
          {t('serv.p1')}
          </p>
        </div>
      </div>
    
    </div>


    <div className="flex h-auto pt-20  items-center justify-center">
        <div className="bg-white flex flex-col h-[50vh] w-[700px]  items-center p-6 rounded-lg text-center">
            
          <img src="../img/Affidabilita-Gerussi.png" alt="Image" 
          className="w-32 " />
            <h3 className="text-2xl font-bold">{t('serv.assistenza')}</h3>
            <p className="mt-2">
            {t('serv.p2')}
            </p>
        </div>
  
      </div>

      <div className='flex flex-col lg:flex-row px-4 gap-4'>
          <div className='w-full lg:w-1/3 bg-gray-200 p-4'>
          <h4 className="text-xl font-black">{t('serv.setup')}</h4>
            <p className="mt-2">
            {t('serv.p3')}
            </p>
            </div>
            <div className='w-full lg:w-1/3 bg-gray-200 p-4'>
          <h4 className="text-xl font-black">{t('serv.remoto')}</h4>
            <p className="mt-2">
            {t('serv.p4')}
            </p>
            </div>       
            <div className='w-full lg:w-1/3 bg-gray-200 p-4'>
          <h4 className="text-xl font-black">{t('serv.interventi')}</h4>
            <p className="mt-2">
            {t('serv.p5')}
            </p>
            </div>
        </div>

        <div className='flex flex-col lg:flex-row px-4 gap-4 my-10 h-[50vh]'>
          <div className='w-full lg:w-1/2 bg-cover bg-gray-800 bg-blend-multiply bg-opacity-70 lg:bg-opacity-30 hover:bg-opacity-80 transition-colors duration-500 text-white content-center bg-no-repeat bg-[url("/img/Macchinari-per-imbottire.webp")] p-4'>
          <h4 className="text-4xl font-black">{t('serv.manutenzione')}</h4>
            <p className="mt-2">
            {t('serv.p6')}
            </p>
            </div>
            <div className='w-full lg:w-1/2 bg-cover bg-gray-800 bg-blend-multiply bg-opacity-70 lg:bg-opacity-30 hover:bg-opacity-80 transition-colors duration-500 text-white content-center bg-no-repeat bg-[url("/img/Macchina-per-imbottire.webp")] p-4'>
          <h4 className="text-4xl font-black">{t('serv.ricambi')}</h4>
            <p className="mt-2">
            {t('serv.p7')}
            </p>
            </div>       
          
        </div>

    

    <ContactForm />
  </div>
}

export default Servizi;