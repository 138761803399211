import React from 'react';
import ContactForm from '../../components/Contact';
import { NavLink } from 'react-router-dom';
import LocalizedNavLink from '../../utils/LocalizedNavLink';
import getPathByKey from '../../utils/getPathByKey ';

const ProductTemplate = ({ 
  img1Src, img1Alt, title, subtitle, description, 
  features, tecnicals, directives, img2Src, img2Alt, options, warranty, 
  specs, relatedProducts 
}) => {
  return (
    <>
      <div className="main-content transition-opacity bg-white duration-500 w-full pt-20 px-4 md:px-4 lg:px-10 2xl:px-40">
        {/* First Section */}
        <div className="flex flex-col md:flex-row items-center mb-10">
          <div className="md:w-1/2">
            <img src={img1Src} alt={img1Alt} className="w-full h-auto p-10"/>
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h1 className="text-4xl lg:text-6xl  text-gerussiRed font-light mb-2">{title}</h1>
            <h2 className="text-2xl font-bold text-enoDark mb-4">{subtitle}</h2>
            <p className="mb-6">{description}</p>
            <div className="flex space-x-2">
              {features.map((feature, index) => (
                <div key={index} className="h-16 px-4 bg-gray-300 flex items-center justify-center ">
                  <span>{feature}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Second Section */}
        <div className="flex flex-col md:flex-row items-center mb-10">
          <div className="md:w-1/2 md:pr-8">
            <h2 className="text-3xl font-bold mb-4">Caratteristiche</h2>
            <ul className="list-disc pl-5">
              {tecnicals.map((tecnical, index) => (
                <li key={index}>{tecnical}</li>
              ))}
            </ul>
            <p className='mt-4 underline'>{directives}</p>
          </div>
          <div className="md:w-1/2">
            <img src={img2Src} alt={img2Alt} className="w-full h-auto"/>
          </div>
        </div>

        {/* Third Section */}
        <div className="flex flex-col md:flex-row items-start mb-2">
        { options.length === 0 ? "" : <div className="md:w-1/2 md:pr-8">
            <h3 className="text-2xl font-bold mb-2">Opzioni a Richiesta</h3> 
            <ul className="list-disc pl-5">
              {options.map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
          </div> }
          <div className="md:w-1/2 md:pl-4">
            <h3 className="text-2xl font-bold mb-2">Garanzia</h3>
            <p>{warranty}</p>
          </div>
        </div>

        {/* Table Section */}
        <div className="overflow-x-auto mb-20">
          <table className="min-w-full  text-left">
            <thead>
              <tr className='bg-gray-800 text-white'>
                {specs.headers.map((header, index) => (
                  <th key={index} className="py-2 px-4 border-b">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody className='bg-gray-200'>
              <tr>
                {specs.values.map((value, index) => (
                  <td key={index} className="py-2 px-4 border-b">{value}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        {/* Related Products Section */}
        <div className="my-10 lg:my-12 xl:my-52 px-4 lg:px-10 xl:px-40">
          <h3 className="text-3xl font-bold mb-4">Prodotti Correlati</h3>
          <div className="flex flex-col md:flex-row ">
            {relatedProducts.map((product, index) => (
              <div key={index} className="w-full md:w-1/2 mb-4 border transition-shadow duration-500 ease-in-out hover:shadow-xl">
                <LocalizedNavLink to={getPathByKey(product.title)} >
                  <img src={product.img}/>
                  <div className='p-4'>
                    <h3 className="text-4xl text-gerussiRed font-bold mt-2">{product.title}</h3>
                    <p>{product.description}</p>
                  </div>
                </LocalizedNavLink>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};

export default ProductTemplate;
