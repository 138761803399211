// src/components/language-selector.js

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { languages, routesConfig } from "../routesConfig"; // Ensure 'languages' is imported

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  // Function to switch language
  const changeLanguage = (lng) => {
    if (lng === i18n.language) return;

    // Find the current and target language groups
    const currentLangGroup =
      routesConfig.find((group) => group.lang === i18n.language) ||
      routesConfig.find((group) => group.lang === "it"); // default to 'it' if not found
    const targetLangGroup = routesConfig.find((group) => group.lang === lng);

    if (!targetLangGroup) {
      console.warn(`Language group for "${lng}" not found.`);
      return;
    }

    // Get the relative path without the basePath
    let relativePath = location.pathname;

    if (currentLangGroup.basePath !== "/") {
      if (location.pathname.startsWith(currentLangGroup.basePath)) {
        relativePath = location.pathname.replace(
          currentLangGroup.basePath,
          ""
        );
      } else {
        relativePath = "/";
      }
    }

    // Ensure relativePath starts with '/'
    if (!relativePath.startsWith("/")) {
      relativePath = "/" + relativePath;
    }

    // Function to find the current route by path
    const findRouteByPath = (pathname, group) => {
      const findInRoutes = (routes) => {
        for (let route of routes) {
          if (route.path === pathname) {
            return route;
          }
          if (route.categories) {
            for (let category of route.categories) {
              if (category.path === pathname) {
                return category;
              }
              if (category.products) {
                for (let productKey of category.products) {
                  const product = group.products[productKey];
                  if (product && product.path === pathname) {
                    return product;
                  }
                }
              }
            }
          }
          if (route.pages) {
            for (let page of route.pages) {
              if (page.path === pathname) {
                return page;
              }
            }
          }
        }
        return null;
      };
      return findInRoutes(group.routes);
    };

    // Function to find the target route by key
    const findRouteByKey = (key, group) => {
      const findInRoutes = (routes) => {
        for (let route of routes) {
          if (route.key === key) {
            return route;
          }
          if (route.categories) {
            for (let category of route.categories) {
              if (category.key === key) {
                return category;
              }
              if (category.products) {
                for (let productKey of category.products) {
                  if (productKey === key) {
                    return group.products[productKey];
                  }
                }
              }
            }
          }
          if (route.pages) {
            for (let page of route.pages) {
              if (page.key === key) {
                return page;
              }
            }
          }
        }
        return null;
      };
      return findInRoutes(group.routes);
    };

    const currentRoute = findRouteByPath(relativePath, currentLangGroup);
    let targetPath = "/";

    if (currentRoute && currentRoute.key) {
      const targetRoute = findRouteByKey(currentRoute.key, targetLangGroup);
      if (targetRoute) {
        targetPath = targetRoute.path;
      }
    }

    i18n.changeLanguage(lng);

    // Construct the new path
    const newPath =
      targetLangGroup.basePath === "/"
        ? targetPath
        : `${targetLangGroup.basePath}${targetPath}`;

    navigate(newPath);
    closeDropdown();
  };

  // Optionally, handle browser language on first load
  useEffect(() => {
    const browserLang = navigator.language.split("-")[0];
    const hasLanguageInPath = routesConfig.some((group) => {
      return group.basePath && location.pathname.startsWith(group.basePath);
    });

    if (
      !hasLanguageInPath &&
      languages.includes(browserLang) &&
      browserLang !== i18n.language
    ) {
      changeLanguage(browserLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]); // Added 'location.pathname' to dependencies

  return (
    <div className="relative z-40">
      <button
        className="focus:outline-none flex items-center gap-0"
        onClick={toggleDropdown}
      >
        <FontAwesomeIcon icon={faGlobe} className="mr-2" />
        {languages.includes(i18n.language)
          ? i18n.language.toUpperCase()
          : "EN"}
      </button>
      {isOpen && (
        <div
          className="absolute top-full bg-gerussiRed right-0 border border-none rounded shadow p-2 z-40"
          onMouseLeave={closeDropdown}
        >
          {languages
            .filter((lng) => lng !== i18n.language)
            .map((lng) => (
              <div
                key={lng}
                className="w-full text-left hover:bg-enoDark py-1 px-2 cursor-pointer"
                onClick={() => changeLanguage(lng)}
              >
                {lng.toUpperCase()}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};


export default LanguageSelector;
