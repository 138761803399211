import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../../components/Contact';
import LocalizedNavLink from '../../utils/LocalizedNavLink';
import getPathByKey from '../../utils/getPathByKey ';

export default function PrepareOpen() {
    
    const { t } = useTranslation("global");
    const relatedProducts = {
      relatedProducts: t('cd400.relatedProducts', { returnObjects: true })
    };
    
    return <>
            
      <div className="mt-20 flex flex-col lg:flex-row p-2 lg:p-20">
            <div className='w-full lg:w-2/5'>
            <img className='mix-blend-multiply' src="/img/Aprifibra-per-imbottitura-CD4001-Gerussi.webp"/>
            </div>
            <div className='w-full lg:w-3/5 self-center'>
            <h1 className="text-3xl uppercase font-bold text-gerussiRed mb-2">{t('nav.prepareT.h')}</h1>
            <p className="text-base">{t('nav.prepareT.p')}</p>
            </div>
        </div>
        <div className="my-10 lg:my-12 xl:my-52 px-4 lg:px-10 xl:px-40">
          <h3 className="text-3xl font-bold mb-4">{t('nav.related')}</h3>
          <div className="flex flex-col md:flex-row ">
          {relatedProducts.relatedProducts.map((product, index) => (
              <div key={index} className="w-full md:w-1/2 mb-4 border transition-shadow duration-500 ease-in-out hover:shadow-xl">
                <LocalizedNavLink to={getPathByKey(product.title)} >
                  <img src={product.img}/>
                  <div className='p-4'>
                    <h3 className="text-4xl text-gerussiRed font-bold mt-2">{product.title}</h3>
                    <p>{product.description}</p>
                  </div>
                </LocalizedNavLink>
              </div>
            ))}
          </div>
        </div>
        <ContactForm />
    </>
}
