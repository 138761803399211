import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLocation, faLocationPin, faMapLocation, faMapMarker, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
  const { t } = useTranslation("global");

  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    consent: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [responseMessage, setResponseMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://agerussi.com/form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result.status === 'success') {
        setResponseMessage(t('form.onSuccess'));
        setMessageType('success');
        setFormData(initialFormData);
      } else {
        setResponseMessage(`Error: ${result.error.join(', ')}`);
        setMessageType('error');
      }
    } catch (error) {
      setResponseMessage(t('form.onFail'));
      setMessageType('error');
    }
  };

  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        setResponseMessage('');
        setMessageType('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [responseMessage]);

  return (
      <div className="px-4 md:px-10 lg:px-20 bg-[url('../img/sfondo-contattaci.webp')] bg-90% bg-left bg-no-repeat h-auto py-20
       flex flex-col lg:flex-row gap-4">
        <div className='w-ful lg:w-1/3 flex flex-col justify-between'>
        <div>
        <h5 className="text-3xl lg:text-5xl uppercase text-gerussiRed font-bold ">{t('form.contact')}</h5>
        <p className="mt-1 text-xs pb-5 leading-snug">{t('home.consulenzaP')}</p>
        </div>
        <div>
       
        <a className='text-[#202020] flex flex-row mb-2 hover:text-gerussiRed transition-colors duration-700 font-serif text-xl items-center gap-3' target='_blank' href="mailto:info@enotorrerecords.com">
         <FontAwesomeIcon icon={faEnvelope}
          className="text-4xl  sm:text-2xl align-top md:text-4xl "
        />info@agerussi.com</a>
        <a className='text-[#202020] hover:text-green-600 transition-colors duration-700 flex flex-row mb-2  font-serif text-xl items-center gap-3' target='_blank' href="https://wa.me/393886064901?text=I'm%20interested%20in%20purchasing%20the%20CD%20from%20Enotorrerecords.com">
        <FontAwesomeIcon icon={faWhatsapp}
        className="text-4xl sm:text-2xl  align-top md:text-4xl "
      />+39 0427 41205</a> <a className='text-[#202020] flex flex-row mb-2 hover:text-gerussiRed transition-colors duration-700 font-serif text-xl items-center gap-3' target='_blank' href="https://maps.app.goo.gl/eEWMAu7UPjXYmgAm6">
      <FontAwesomeIcon icon={faMapMarkerAlt}
       className="text-4xl  sm:text-2xl align-top md:text-4xl "
     />Via C. Malignani, 5 33097 Spilimbergo (PN)</a>
      </div>
        </div>
        <div className='w-full lg:w-2/3'>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-6">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder={t('form.first')}
                className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
                required
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder={t('form.last')}
                className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed  rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-2 lg:mt-4">
            <input
              type="email"
              name="email"
              placeholder="E-mail*"
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed  rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mt-2 lg:mt-4">
            <input
              type="text"
              name="subject"
              placeholder={t('form.subject')}
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed  rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
          <div className="mt-2 lg:mt-4">
            <textarea
              name="message"
              placeholder={t('form.message')}
              rows="4"
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed  rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mt-4 flex items-center">
            <input
              type="checkbox"
              name="consent"
              id="consent"
              className="mr-2 text-gerussiRed border-gerussiRed bg-gerussiRed focus:ring-gerussiRed "
              required
              checked={formData.consent}
              onChange={handleChange}
            />
            <label htmlFor="consent" className="text-neutral-500">
              {t('form.autorize')} <a href="/privacy" className="text-gerussiRed">Privacy Policy</a>*
            </label>
          </div>
          <div className="mt-8">
          <button
            type="submit"
            className="text-enoDark p-2 text-base px-6 border-2 mt-0 md:mt-4 hover:border-gerussiRed border-enoDark
             hover:rounded-lg hover:bg-gerussiRed hover:-rotate-1 uppercase leading-[1] transition-rotate 
             duration-300 ease-in-out text-center w-80 "
          >
            {t('form.send')}
          </button>
        </div>
      </form>
      {responseMessage && (
        <div className={`mt-4 p-4 rounded-md ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {responseMessage}
        </div>
      )}
      </div>
    </div>
  );
};

export default ContactForm;