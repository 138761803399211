import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const TP1 = () => {
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('tp1.img1Src'),
    img1Alt: t('tp1.img1Alt'),
    title: t('tp1.title'),
    subtitle: t('tp1.subtitle'),
    description: t('tp1.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('tp1.features', { returnObjects: true }))
      ? t('tp1.features', { returnObjects: true })
      : [],

    tecnicals: t('tp1.tecnicals', { returnObjects: true }),
    img2Src: t('tp1.img2Src'),
    img2Alt: t('tp1.img2Alt'),
    options: t('tp1.options', { returnObjects: true }),
    warranty: t('tp1.warranty'),
    specs: {
      headers: t('tp1.specs.headers', { returnObjects: true }),
      values: t('tp1.specs.values', { returnObjects: true })
    },
    relatedProducts: t('tp1.relatedProducts', { returnObjects: true })
  };
  

  return <ProductTemplate {...productData} />;
};

export default TP1;
