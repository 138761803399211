import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const TG500 = () => {
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('tg500.img1Src'),
    img1Alt: t('tg500.img1Alt'),
    title: t('tg500.title'),
    subtitle: t('tg500.subtitle'),
    description: t('tg500.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('tg500.features', { returnObjects: true }))
      ? t('tg500.features', { returnObjects: true })
      : [],

    tecnicals: t('tg500.tecnicals', { returnObjects: true }),
    directives: t('tg500.directives'),
    img2Src: t('tg500.img2Src'),
    img2Alt: t('tg500.img2Alt'),
    options: t('tg500.options', { returnObjects: true }),
    warranty: t('tg500.warranty'),
    specs: {
      headers: t('tg500.specs.headers', { returnObjects: true }),
      values: t('tg500.specs.values', { returnObjects: true })
    },
    relatedProducts: t('tg500.relatedProducts', { returnObjects: true })
  };

  return <ProductTemplate {...productData} />;
};

export default TG500;
