import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ParallaxSection from '../components/ParallaxSection';
import Timeline from '../components/Timeline';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';

const images = [
    '/img/Studi-meccanici-particolare-officina-gerussi-spilimbergo.webp',
    '/img/Riempitrice-automatica-cuscino-in-lavorazione-gerussi.webp',
    '/img/Particolari-di-LEGAMATIC-Details-of-LEGAMATIC.webp',
    // add more image URLs as needed
  ];



function AboutUs() {
  const { t } = useTranslation("global");
  const timelineRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(0);

  const timelineData = [
    { year: 1957, content: t('chi.storia1'), image: "/img/A57_prima-macchina-gerussi.webp" },
/*     { year: 1962, content: t('chi.storia2') },
    { year: 1971, content: t('chi.storia3') }, */
    { year: 1984, content: t('chi.storia4'), image: "/img/capannone-1984-vista-aerea.webp" },
    { year: 1994, content: t('chi.storia5'), image: "/img/SR1_prima-macchina-apertura-fibra.webp" },
    { year: 1998, content: t('chi.storia6'), image: "/img/SR3C-macchina.webp" },
/*     { year: 2006, content: t('chi.storia7') },
    { year: 2012, content: t('chi.storia8') }, 
    { year: 2019, content: t('chi.storia9') },
    { year: 2020, content: t('chi.storia10') },*/
    { year: 2024, content: t('chi.storia11'), image: "/img/pannelli-solari-tetto-gerussi.jpeg" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);
  const [videoReady, setVideoReady] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    if (videoElement) {
      console.log("Video element found!")

      const handleCanPlayThrough = () => {
        console.log("Video is ready to play through!")
        setVideoReady(true);
        setIsLoading(false);
      };
      videoElement.addEventListener("canplaythrough", handleCanPlayThrough);

      return () => {
        console.log("Cleanup: Removing event listener")
        videoElement.removeEventListener("canplaythrough", handleCanPlayThrough);
      };
    } else {
      console.log("Video element not found!")
    }
  }, []);

  return (
    <>
   
      <div className="main-content  bg-[#f4f4f4] transition-opacity duration-500 overflow-x-hidden">
      <div className="h-[95vh] lg:h-screen flex flex-col">
    <section className="flex-1 relative">
      {isLoading && (
        <img
          src="../img/videofallback.webp"
          alt="Fallback Image"
          className="absolute inset-0 w-full h-full object-cover"
        />
      )}

      <video
        id="background-video"
        autoPlay
        muted
        loop
        key={'material_video'} // Add this key prop
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${videoReady ? 'opacity-100' : 'opacity-0'}`}
      >
        <source src="../vid/gerussi_drone.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="bg-[#f4f4f4cc] absolute bottom-0 z-20  h-auto md:h-[40vh] px-4 md:px-20 lg:px-40  py-10 w-full 
        bg-[url('/img/feeling-for-feelings-white.png')] bg-no-repeat bg-center bg-60% text-center ">
            <h1 className='text-4xl md:text-5xl text-gerussiRed mb-3'>{t('chi.h1')}</h1>
            <p className="text-sm xl:text-base">{t('chi.p')}</p>
            <p className='mt-3 text-gerussiRed'><strong>{t('chi.strong')}</strong></p> 
          </div>
    </section>
  </div>
       



        <section className='flex flex-col gap-2 p-4 my-40 '>
            <div className='flex flex-col lg:flex-row  w-full gap-2 h-full'>
                <div className="w-full lg:w-1/2 h-40 lg:h-auto rounded-lg  bg-[url('/img/materiale-fibra-per-imbottitura-gerussi-dettaglio.webp')] bg-cover  bg-center"></div>
                <div className='w-full lg:w-1/2 h-auto lg:h-full rounded-lg bg-[#f4f4f4] content-center p-5'>
                <h2 className='text-3xl text-gerussiRed mb-3'>{t('chi.mission')}</h2>
                <p className='text-base'>{t('chi.missionP')}
                    </p>
               
                 </div>
            </div>
            <div className='flex flex-col-reverse lg:flex-row w-full gap-2 h-full'>

                <div className='w-full lg:w-1/2 h-auto lg:h-full rounded-lg bg-[#f4f4f4] content-center p-5'>
                <h2 className='text-3xl text-gerussiRed mb-3'>{t('chi.vision')}</h2>
                <p className='text-base'>{t('chi.visionP')}</p>
                </div>
                <div className="w-full lg:w-1/2 h-40 lg:h-auto rounded-lg  bg-[url('/img/tecnologia-lavorazione-imbottitura-mission-gerussi.webp')] bg-cover"></div>
            </div>
        </section>


{/*  slider  */}
<div className="relative  w-full h-[50vh] md:h-[80vh] overflow-hidden">
      <div className="absolute w-full h-full overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute w-full h-full transition-all duration-[1200ms] ease-in-out transform ${
              index === currentImage ? 'translate-y-0 opacity-100 ' : 'translate-y-full  opacity-0'
            }`}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
        ))}
      </div>
    
    </div>

{/* timeline section */}

        <section ref={timelineRef} className="p-4 md:p-8 bg-[#f4f4f4] z-0 relative">
        <div className="absolute z-20 -top-40 left-1/2 -translate-x-1/2 w-11/12  md:w-[560px] flex items-center justify-center">
        <div className="bg-white flex flex-col p-6 rounded-lg text-center">
            
        <img src="../img/100-gerussi-logo.svg" alt="Image" className="h-32 justify-center mb-4 md:w-full" />
          <h3 className="text-2xl font-bold">100% Made in Gerussi</h3>
          <p className="mt-2">{t('chi.madeP')}</p>
        </div>
      </div>
          <div className="max-w-5xl mt-52 md:mt-32 mx-auto relative">
          <Timeline timelineData={timelineData} />
          </div>
        </section>
        <ContactForm />
      </div>
    </>
  );
}

export default AboutUs;

