import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {


    return    <footer className="bg-gray-900 text-white py-12">
      
    <div className=" mx-auto px-4 sm:px-6 xl:px-40">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-3">
        <div>
          <img src="../img/gerussi-feelings-for-feelings-logo.png" alt="Company Logo" className="w-3/4" />
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">Contacts</h3>
          <p>
            <strong>GERUSSI di Gerussi Alessio &amp; C. sas</strong>
            <br />
            Via C. Malignani, 5, 33097 Spilimbergo PN
            <br />
            Tel: 0427 41205
            <br />
            info@agerussi.com
            <br />
            p.iva: 01862960935
          </p>
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">Subscribe to our newsletter</h3>
          <div className="flex flex-row gap-2 items-center">
            <input
              type="email"
              placeholder="Email address"
              className="w-full px-4 py-2 mb-2 border border-gray-600 rounded-md
               bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button className="px-4 py-2 -mt-2 bg-red-800 text-white rounded-md hover:bg-red-400
            transition-colors duration-300">
              Subscribe
            </button>
          </div>
        <div className="flex space-x-4 mt-4">
          <a href="#" className="text-white"><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="#" className="text-white"><FontAwesomeIcon icon={faLinkedin} /></a>
        </div>
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">Menu</h3>
          <ul className="grid grid-cols-4 md:grid-cols-1">
            <li>
              <a href="#" className="hover:text-gray-400">
                Home
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-400">
                Azienda
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-400">
                Servizi
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-400">
                Contatti
              </a>
            </li>
          </ul>
        </div>
      </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="mt-10 flex justify-between items-center">
        <div>
          <p className="text-center">
          Obblighi informativi per le erogazioni pubbliche: gli aiuti di Stato e gli aiuti de minimis ricevuti dalla nostra impresa sono contenuti nel Registro nazionale degli aiuti di Stato di cui all'art. 52 della L. 234/2012 a cui si rinvia e consultabili al seguente link.
        </p>
        </div>
      </div>
      <div className="mt-9 text-gray-400">
        <p className="text-center">
          
          <img src="../img/NEW_STRISCIA_LOGHI_ORIZZ_rgb-2048x205.webp" alt="Company Logo" className="h-3/4" />
          IMPIANTO FOTOVOLTAICO E SISTEMA DI ACCUMULO (<a href="#" className="bg-red-800 text-white rounded-md hover:bg-red-400">Link bando</a>)
        </p>
      </div>
    </div>
    </div>
    
    <p className="text-center mt-10">&copy; {new Date().getFullYear()} Gerussi - @Kosteaz</p>
  </footer>
}