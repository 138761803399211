import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const T300 = () => {
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('t300.img1Src'),
    img1Alt: t('t300.img1Alt'),
    title: t('t300.title'),
    subtitle: t('t300.subtitle'),
    description: t('t300.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('t300.features', { returnObjects: true }))
      ? t('t300.features', { returnObjects: true })
      : [],

    tecnicals: t('t300.tecnicals', { returnObjects: true }),
    img2Src: t('t300.img2Src'),
    img2Alt: t('t300.img2Alt'),
    options: t('t300.options', { returnObjects: true }),
    warranty: t('t300.warranty'),
    specs: {
      headers: t('t300.specs.headers', { returnObjects: true }),
      values: t('t300.specs.values', { returnObjects: true })
    },
    relatedProducts: t('t300.relatedProducts', { returnObjects: true })
  };

  
  return <ProductTemplate {...productData} />;
};

export default T300;
