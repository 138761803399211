import { faArrowDown, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";

const FAQ = ({ question, answer, openFAQIndex, setOpenFAQIndex, faqIndex }) => {
    const [height, setHeight] = useState('0px');
    const contentRef = useRef(null);
  
    const isOpen = openFAQIndex === faqIndex;
  
    const handleToggle = useCallback(() => {
      setOpenFAQIndex(prevIndex => (prevIndex === faqIndex ? null : faqIndex));
    }, [faqIndex, setOpenFAQIndex]);
  
    useEffect(() => {
      if (isOpen) {
        setHeight(`${contentRef.current.scrollHeight}px`);
      } else {
        setHeight('0px');
      }
    }, [isOpen]);
  
    return (
      <div className="faq-container border-b border-gray-300">
        <button
          className="faq-button w-full flex justify-between items-center py-1 sm:py-2 text-left hover:text-gerussiRed focus:outline-none"
          onClick={handleToggle}
        >
          <span
            className={`font-regular text-sm uppercase font-black sm:text-base md:text-lg ${
              isOpen ? 'text-gerussiRed' : 'text-black'
            } hover:text-gerussiRed`}
          >
            {question}
          </span>
          <FontAwesomeIcon 
            icon={isOpen ? faArrowDown : faArrowLeft} 
            className={`ml-4 transition-transform ease-[cubic-bezier(0.34,1.90,0.64,1)] duration-500 ${isOpen ? 'rotate-180' : ''}`} 
          />
        </button>
        <div
          ref={contentRef}
          className="ease-[cubic-bezier(0.34,1.56,0.64,1)] transi-height duration-500"
          style={{
            height: height,
            overflow: 'hidden',
          }}
        >
          <div className="pb-3 text-gerussiRed text-sm mt-1 sm:text-base leading-[1.1] sm:leading-tight font-serif">{answer}</div>
        </div>
      </div>
    );
  };

  export default FAQ