// src/components/NotFound.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-gerussiRed">404</h1>
      <p className="mt-4 text-xl">{t('notFound.message')}</p>
      <Link to="/" className="mt-6 px-4 py-2 bg-gerussiRed text-white rounded hover:bg-red-600 transition">
        {t('notFound.goHome')}
      </Link>
    </div>
  );
};

export default NotFound;
