import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TimelineItem = ({ year, content, image, isLeft, isActive }) => {
  return (
    <div className={`flex items-center mb-4 md:mb-20 ${isActive ? 'opacity-100' : 'opacity-50'}  ${isLeft ? 'flex-row-reverse' : ''}`}>
      <div className={`w-11/12 md:w-5/12 min-h-[450px] content-center ${isLeft ? 'text-left' : 'text-right'}`}>
        {image && <img src={image} alt={`${year} image`} className={`w-2/3 ${isLeft ? 'mr-auto' : 'ml-auto'} rounded-lg grayscale`} />}
        <h3 className={`text-xl md:text-2xl font-bold mb-2 transition-all duration-300 ${isActive ? 'text-gerussiRed' : 'text-gray-500'}`}>
          {year}
        </h3>
        <p className={`text-sm md:text-base transition-all duration-300 `}>
          {content}
        </p>
      </div>
      <div className="w-2/12 flex justify-center relative">
        <motion.div 
          className={`w-3 h-3 md:w-4 md:h-4 rounded-full absolute transition-all duration-300 ${isActive ? 'bg-gerussiRed' : 'bg-gray-300'}`}
          style={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          initial={{ scale: 1 }}
          animate={{ scale: isActive ? 1.25 : 1 }}
          transition={{ duration: 0.3 }}
        />
      </div>
      <div className="w-1/12"></div>
    </div>
  );
};

const CurvedLine = ({ progress, height, activeIndex, totalItems }) => {
  const svgRef = useRef(null);
  const pathRef = useRef(null);
  const [svgHeight, setSvgHeight] = useState(height);
  const amplitude = 40;
  const frequency = 0.015;

  const generatePath = (height) => {
    let path = `M 50 0`;
    for (let y = 0; y <= height; y += 10) {
      const x = 50 + amplitude * Math.sin(frequency * y);
      path += ` L ${x} ${y}`;
    }
    return path;
  };

  useEffect(() => {
    const updateSvgHeight = () => {
      if (svgRef.current) {
        const newHeight = svgRef.current.parentElement.clientHeight;
        setSvgHeight(newHeight);
      }
    };

    updateSvgHeight();
    window.addEventListener('resize', updateSvgHeight);
    return () => window.removeEventListener('resize', updateSvgHeight);
  }, []);

  useEffect(() => {
    if (pathRef.current) {
      const length = pathRef.current.getTotalLength();
      pathRef.current.style.strokeDasharray = length;
      pathRef.current.style.strokeDashoffset = length;
    }
  }, [svgHeight]);

  useEffect(() => {
    if (pathRef.current) {
      const length = pathRef.current.getTotalLength();
      const drawLength = length * progress;
      pathRef.current.style.strokeDashoffset = length - drawLength;
    }
  }, [progress, svgHeight]);

  const path = generatePath(svgHeight);

  return (
    <svg ref={svgRef} className="absolute left-1/2 top-0 h-full transform -translate-x-1/2" width="100" height={svgHeight}>
      <path
        d={path}
        fill="none"
        stroke="#e5e7eb"
        strokeWidth="2"
      />
      <path
        ref={pathRef}
        d={path}
        fill="none"
        stroke="#EF4444"
        strokeWidth="2"
        className="transition-all duration-[1200ms] ease-in-out"
      />
      {/* {activeIndex !== null && (
        <motion.circle
          cx={50 + amplitude * Math.sin(frequency * (svgHeight * (activeIndex / (totalItems - 1))))}
          cy={svgHeight * (activeIndex / (totalItems - 1))}
          r="6"
          fill="#EF4444"
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 300, damping: 10 }}
        />
      )} */}
    </svg>
  );
};

const Timeline = ({ timelineData }) => {
  const timelineRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const itemRefs = useRef([]);

  const { t } = useTranslation("global");

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, timelineData.length);
  }, [timelineData]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-20px',
      threshold: 0.9,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = itemRefs.current.findIndex(ref => ref === entry.target);
          if (index !== -1) {
            setActiveIndex(index);
          }
        }
      });
    }, options);

    itemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      itemRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [timelineData]);

  return (
    <section ref={timelineRef} className="p-4 md:p-8 relative">
      <h2 className="text-2xl md:text-3xl text-gerussiRed mb-8 text-center">
        {t('chi.storiaH')}</h2>
      <div className="max-w-5xl mx-auto relative">
        <CurvedLine
          progress={(activeIndex + 0.8) / timelineData.length}
          height={timelineRef.current?.clientHeight || 0}
          activeIndex={activeIndex}
          totalItems={timelineData.length}
        />
        {timelineData.map((item, index) => (
          <div key={item.year} ref={el => itemRefs.current[index] = el}>
            <TimelineItem 
              {...item} 
              isLeft={index % 2 === 0} 
              isActive={index === activeIndex}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Timeline;