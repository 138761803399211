import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

const LavoraConNoiForm = () => {
  
  const { t } = useTranslation("global");
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    file: null,
    consent: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [responseMessage, setResponseMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : (type === 'file' ? files[0] : value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('email', formData.email);
    data.append('file', formData.file);
    data.append('consent', formData.consent);

    try {
      const response = await fetch('https://agerussi.com/lavora.php', {
        method: 'POST',
        body: data,
      });
      const result = await response.json();
      if (result.status === 'success') {
        setResponseMessage(t('form.onSuccess'));
        setMessageType('success');
        setFormData(initialFormData);
      } else {
        setResponseMessage(`Error: ${result.error.join(', ')}`);
        setMessageType('error');
      }
    } catch (error) {
      setResponseMessage(t('form.onFail'));
      setMessageType('error');
    }
  };

  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        setResponseMessage('');
        setMessageType('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [responseMessage]);

  return (
    <div className="px-4 md:px-10 lg:px-20  bg-90% bg-left bg-no-repeat h-auto py-20
      flex flex-col lg:flex-row-reverse gap-4">
      <div className='w-full lg:w-1/3 flex flex-col justify-between'>
        <h2 className="text-7xl text-gerussiRed font-bold mb-2 md:mb-8">{t('form.h2')}</h2>
        <div>
            <p>
           {t('form.p')}
            </p>
          
        </div>
      </div>
      <div className='w-full lg:w-2/3'>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-6">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder={t('form.first')}
                className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
                required
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder={t('form.last')}
                className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-2 lg:mt-4">
            <input
              type="email"
              name="email"
              placeholder="E-mail*"
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mt-2 lg:mt-4">
            <input
              type="file"
              name="file"
              accept=".pdf"
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-gerussiRed rounded-md focus:outline-none text-enoDark bg-[#cccc] focus:ring-2 focus:ring-gerussiRed"
              required
              onChange={handleChange}
            />
          </div>
          <div className="mt-4 flex items-center">
            <input
              type="checkbox"
              name="consent1"
              id="consent1"
              className="mr-2 text-gerussiRed border-gerussiRed bg-gerussiRed focus:ring-gerussiRed"
              required
              checked={formData.consent1}
              onChange={handleChange}
            />
              <label htmlFor="consent1" className="text-neutral-500">
              {t('form.autorize')} <a href="/privacy" className="text-gerussiRed">Privacy Policy</a>*
            </label>
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="text-enoDark p-2 text-base px-6 border-2 mt-0 md:mt-4 hover:border-gerussiRed border-enoDark hover:rounded-lg hover:bg-gerussiRed hover:-rotate-1 uppercase leading-[1] transition-rotate duration-300 ease-in-out text-center w-80"
            >
            {t('form.send')}
            </button>
          </div>
        </form>
        {responseMessage && (
          <div className={`mt-4 p-4 rounded-md ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {responseMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default LavoraConNoiForm;
