import { useTranslation } from "react-i18next";
import FlipImage from "../../components/FlipImage";
import ContactForm from "../../components/Contact";
import FAQ from "../../components/Faq";
import { useState } from "react";

export default function sofas() {
  const { t } = useTranslation("global");
  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const faqs = [
    {
      question: t("sofas.faq.q1"),
      answer: t("sofas.faq.a1"),
    },
    {
      question: t("sofas.faq.q2"),
      answer: t("sofas.faq.a2"),
    },
    {
      question: t("sofas.faq.q3"),
      answer: t("sofas.faq.a3"),
    },
    {
      question: t("sofas.faq.q4"),
      answer: t("sofas.faq.a4"),
    },
  ];

  return <>
    <div className="main-content transition-opacity duration-500 overflow-x-hidden">
      {/* Hero Section */}
      <section className="h-[50vh] relative bg-[url('/img/imbotticuscini2.png')] bg-center bg-cover ">
        < div className="absolute inset-0 bg-black opacity-50" ></div>
        <div className="container mx-auto px-4 text-center h-full flex">
          <h1 className="text-4xl md:text-5xl font-bold text-white z-30 uppercase self-center">
            {t("sofas.hero.h1")}
          </h1>
        </div>
      </section>

      <div className="flex flex-col md:flex-col items-center pt-20 mb-10 px-2">
        <img src="/img/sofas.webp" className="w-full lg:w-1/3 rounded-lg" />
        <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('sofas.content.h2')}</h1>
        <p className="text-center">{t('sofas.content.p')}</p>

      </div>
      {/* Related Links to Machines */}
      <section className="relative flex flex-col justify-center bg-red-950 items-center max-w-screen-xl py-20 sm:px-5 lg:px-10 md:px-10">
        <h2 className="text-3xl md:text-5xl text-center m-10 font-sans text-white font-bold">
          {t("home.h2")}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 px-2 gap-6">
          <FlipImage to='PrepareOpen' text={t('home.cat1')} imageFront="../img/cd400-vettoriale.png" imageBack="../img/Aprifibra-per-imbottitura-CD4001-Gerussi.webp" />
          <FlipImage to='WeighDose' text={t('home.cat3')} imageFront="../img/sr5-vettoriale.png" imageBack="../img/Rimepitrice-con-pesatura-automatica-per-imbottire-SR5HMI.webp" />
          <FlipImage to='FillLine' text={t('home.cat4')} imageFront="../img/riempire-vettoriale.png" imageBack="../img/Riempitrice-R-3PV2-Gerussi.webp" />
        </div>
      </section>

         {/* FAQ Section */}
         <section className="py-20 md:px-10 lg:px-20 bg-gray-50">
        <div className="mx-auto">
          <div className="flex flex-col lg:flex-row">
            {/* FAQ Title */}
            <div className="lg:w-1/3 mb-8 lg:mb-0">
              <h2 className="text-4xl font-bold text-red-950 mb-4">
                FAQ
              </h2>
            </div>
            {/* FAQ List */}
            <div className="lg:w-2/3">
              {faqs.map((faq, index) => (
                <FAQ
                  key={index}
                  faqIndex={index}
                  question={faq.question}
                  answer={faq.answer}
                  openFAQIndex={openFAQIndex}
                  setOpenFAQIndex={setOpenFAQIndex}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
    </div>
  </>
}