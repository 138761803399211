import React from 'react';
import ProductTemplate from './ProductTemplate';
import { useTranslation } from 'react-i18next';

const AB450NL = () => {
  
  const { t } = useTranslation("global");

  const productData = {
    img1Src: t('ab450.img1Src'),
    img1Alt: t('ab450.img1Alt'),
    title: t('ab450.title'),
    subtitle: t('ab450.subtitle'),
    description: t('ab450.description'),

    // Ensure that features is always an array
    features: Array.isArray(t('ab450.features', { returnObjects: true }))
      ? t('ab450.features', { returnObjects: true })
      : [],

    tecnicals: t('ab450.tecnicals', { returnObjects: true }),
    img2Src: t('ab450.img2Src'),
    img2Alt: t('ab450.img2Alt'),
    options: t('ab450.options', { returnObjects: true }),
    warranty: t('ab450.warranty'),
    specs: {
      headers: t('ab450.specs.headers', { returnObjects: true }),
      values: t('ab450.specs.values', { returnObjects: true })
    },
    relatedProducts: t('ab450.relatedProducts', { returnObjects: true })
  };
  return <ProductTemplate {...productData} />;
};

export default AB450NL;
