import React, { useEffect, useRef, useState } from 'react';

const AnimatedCounter = ({ target, speed = 50, currentValue: initialValue = 0, sub }) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && currentValue < target) {
      const incrementStep = Math.max(1, Math.floor(target / 100)); // Adjust the divisor to control speed
      const intervalId = setInterval(() => {
        setCurrentValue((prevValue) => Math.min(prevValue + incrementStep, target));
      }, speed);

      animationRef.current = intervalId;

      return () => clearInterval(intervalId);
    }
  }, [isVisible, target, speed]);

  useEffect(() => {
    if (currentValue === target) {
      clearInterval(animationRef.current);
    }
  }, [currentValue, target]);

  return (
    <p className="text-5xl text-center font-black  mr-2" ref={counterRef}>
      {currentValue + sub}
    </p>
  );
};

export default AnimatedCounter;