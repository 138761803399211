// src/utils/getPathByKey.js

import { useTranslation } from 'react-i18next';
import { routesConfig } from '../routesConfig';

const getPathByKey = (key) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  // Find the current language group
  const currentLangGroup =
    routesConfig.find((group) => group.lang === currentLang) ||
    routesConfig.find((group) => group.lang === 'en'); // Default to 'en' if not found

  if (!currentLangGroup) {
    console.warn(`Language group for "${currentLang}" not found.`);
    return '/';
  }

  // Function to find the route by key
  const findRouteByKey = (key, routes) => {
    for (let route of routes) {
      if (route.key === key) {
        return route.path;
      }
      if (route.categories) {
        for (let category of route.categories) {
          if (category.key === key) {
            return category.path;
          }
          if (category.products) {
            for (let productKey of category.products) {
              if (productKey === key) {
                const product = currentLangGroup.products[productKey];
                if (product) {
                  return product.path;
                }
              }
            }
          }
        }
      }
      if (route.pages) {
        for (let page of route.pages) {
          if (page.key === key) {
            return page.path;
          }
        }
      }
    }
    return null;
  };

  const path = findRouteByKey(key, currentLangGroup.routes);

  if (!path) {
    console.warn(`Route with key "${key}" not found.`);
    return '/';
  }

  // Since the path already includes the basePath, we don't need to add it again
  const fullPath = path.replace(/\/\/+/g, '/'); // Normalize the path to remove any double slashes

  return fullPath;
};

export default getPathByKey;
