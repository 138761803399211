import React from 'react';
import { useTranslation } from 'react-i18next';
import ParallaxSection from '../components/ParallaxSection';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';
import VideoSection from '../components/VideoSection';
import FlipCard from '../components/FlipCard';

function Sectors() {
    const { t } = useTranslation("global");

    return <div className="main-content transition-opacity duration-500 px-4 md:px-48 py-20 w-full">
        

        <div className="grid font-light lg:p-0 grid-cols-1 lg:grid-cols-2 gap-4 px-2 w-full">
                <FlipCard to='Pillows' text={t('petbeds.content.h2')} ptext={t('petbeds.content.p')} Image="../img/cuscions_pillows.webp" />
                <FlipCard to='Sofas' text={t('pillow.content.h2')} ptext={t('pillow.content.p')} Image="../img/sofas.webp" />
                <FlipCard to='Toys' text={t('sofas.content.h2')} ptext={t('sofas.content.p')} Image="../img/peluches.webp" />
                <FlipCard to='PetBeds' text={t('toys.content.h2')} ptext={t('toys.content.p')} Image="../img/pet_pillows.webp" />
            </div>
  
    <ContactForm />
  </div>
}

export default Sectors;